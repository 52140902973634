import React, { useContext } from 'react'
import {
  StyledListingHearingSlot,
  StyledHearingInfo,
  StyledHearingDate,
  StyledHearingTime,
} from './ContentHeader.styles'
import { ConfigContext } from '../../context/appConfig.jsx'

interface Props {
  content: Content
  addLeftSpacing?: boolean
  currentJurisdiction: string
}

interface Content {
  description?: string
  room?: string
  time?: string
}

export const ContentHeader = ({
  content,
  addLeftSpacing = false,
  currentJurisdiction,
}: Props) => {
  const sortedByTime = ['chcv', 'vcat'].includes(
    currentJurisdiction?.toLowerCase()
  )

  const appConfig = useContext(ConfigContext)

  return (
    <StyledListingHearingSlot
      data-testid="content-header"
      addLeftSpacing={addLeftSpacing}
      appConfig={appConfig}
    >
      <StyledHearingInfo>
        <p>{content?.description}</p>
      </StyledHearingInfo>
      <StyledHearingTime>
        {content && sortedByTime ? <p>Time (Sorted)</p> : <p>Time</p>}
      </StyledHearingTime>
      <StyledHearingDate addLeftSpacing={addLeftSpacing}>
        <p>{content?.room}</p>
      </StyledHearingDate>
    </StyledListingHearingSlot>
  )
}
