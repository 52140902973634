import styled from 'styled-components'

interface Props {
  appConfig?: any
}

export const StyledDateTimeHeader = styled.div<Props>`
  font-family: inherit;
  color: 'black';
  background-color: ${(props) => props.appConfig.minor_color};
  border-bottom: 0.2vh solid ${(props) => props.appConfig.border_color};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
  font-size: 2.2vh;
  padding-left: 8.7vw;

  div {
    align-items: center;
    display: flex;
    height: 5vh;
    padding: 0 4vw;
  }
  div:last-child {
    border-left: 0.2vh solid ${(props) => props.appConfig.border_color};
  }
`
