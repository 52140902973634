import { createGlobalStyle } from 'styled-components'
import Grotesk from '../assets/fonts/HKGrotesk-Regular.otf'
import GroteskLight from '../assets/fonts/HKGrotesk-Light.otf'
import GroteskMedium from '../assets/fonts/HKGrotesk-Medium.otf'
import GroteskBold from '../assets/fonts/HKGrotesk-Bold.otf'

export const FontStyles = createGlobalStyle`
    @font-face {
        font-family: 'HKGrotesk';
        src: url(${Grotesk}) format('opentype');
    }
    @font-face {
        font-family: 'HKGrotesk';
        src: url(${GroteskLight}) format('opentype');
        font-weight: lighter;
    }
    @font-face {
        font-family: 'HKGrotesk';
        src: url(${GroteskMedium}) format('opentype');
        font-weight: medium;
    }
    @font-face {
        font-family: 'HKGrotesk';
        src: url(${GroteskBold}) format('opentype');
        font-weight: bold;
    }
`

const GlobalStyle = createGlobalStyle`

body {
  font-family: 'HKGrotesk';
  margin: 0;
}
`

export default GlobalStyle
