import React, { useContext, useEffect, useState } from 'react'
import {
  StyledJurisdictionHeader,
  StyledNext,
} from './JurisdictionHeader.styles'
import { ConfigContext } from '../../../context/appConfig.jsx'

interface Props {
  loc?: string
  jurisdiction: ValidJurisdictionTypes[] | 'none'
  jur?: string
  screen?: number
  totalScreens?: number
  nextJurisdiction?: string
  pageCeiling?: number
  pivots?: { [jur: string]: number | undefined }
  page?: number
}

export const JurisdictionHeader = ({
  loc = '',
  jurisdiction,
  jur = '',
  screen = undefined,
  nextJurisdiction = 'none',
  totalScreens = 1,
  pageCeiling = 1,
  pivots = {},
  page = 1,
}: Props) => {
  const courts = {
    mcv: 'Magistrates Court',
    cc: 'Country Court',
    sc: 'Supreme Court',
    vcat: 'VCAT Schedule',
    chcv: `Children's Court Schedule`,
    none: '',
  }

  const [showNext, setShowNext] = useState(false)

  const appConfig = useContext(ConfigContext)

  const isSmall = jurisdiction.length > 2 || jurisdiction?.includes('chcv')

  const addLeftSpacing = ['sc', 'cc', 'mcv'].includes(jur.toLowerCase())

  if (loc === 'wcjc') {
    courts.sc = 'Supreme Court Of Victoria'
  }

  useEffect(() => {
    const pivot = pivots[jur]

    if (jurisdiction.length > 1 && pivot !== undefined) {
      if (totalScreens > 1) {
        if (pivot <= totalScreens) {
          if (screen === pivot) {
            setShowNext(true)
          } else {
            setShowNext(false)
          }
        } else {
          const pageEnd = pivot - totalScreens
          if (pageEnd === screen) {
            setShowNext(true)
          } else {
            setShowNext(false)
          }
        }
      } else if (totalScreens === 1) {
        if (page === pivot) {
          setShowNext(true)
        } else {
          setShowNext(false)
        }
      }
    }
  }, [jur, page])

  return (
    <StyledJurisdictionHeader
      screen={screen === 1 || jurisdiction.length > 1}
      isSmall={isSmall}
      addLeftSpacing={addLeftSpacing}
      appConfig={appConfig}
    >
      <h1 id="jurisdictionHeader">
        {jur.length === 0 && screen === 1
          ? 'No hearings scheduled today'
          : courts[jur as keyof typeof courts]}
      </h1>
      {pageCeiling > 1 && pivots[jur] && showNext ? (
        <StyledNext data-testid="nextJurisdictionHeader">
          <p>Up next : </p>
          <p>
            {courts[nextJurisdiction as keyof typeof courts].replace(
              'Schedule',
              ''
            )}
          </p>
        </StyledNext>
      ) : (
        ''
      )}
    </StyledJurisdictionHeader>
  )
}
