import { createTestHearing } from '../util'

export const chcvSortingData: { hearings: HearingType[] } = {
  hearings: [
    createTestHearing({
      hearingId: '0',
      matterDescription: 'D Test Hearing',
      jurisdiction: 'chcv',
    }),
    createTestHearing({
      hearingId: '1',
      matterDescription: 'C Test Hearing',
      jurisdiction: 'chcv',
    }),
    createTestHearing({
      hearingId: '2',
      matterDescription: 'B Test Hearing',
      jurisdiction: 'chcv',
    }),
    createTestHearing({
      hearingId: '3',
      matterDescription: 'A Test Hearing',
      jurisdiction: 'chcv',
    }),
  ],
}
