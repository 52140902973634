import { createContext } from 'react'

const defaultData = {
  major_color: '',
  minor_color: '',
  text_color: '',
  subtext_color: '',
  jurisdiction_header_color: '',
  border_color: '',
  progress_bar_color: '',
  progress_bar_text_color: '',
  progress_bar_speed: '',
}

export const ConfigContext = createContext(defaultData)
