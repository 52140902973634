import React, { useContext, useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import {
  StyledListingHearingSlot,
  StyledHearingInfo,
  StyledHearingTime,
  StyledHearingRoom,
  StyledListingOrder,
} from './LobbyHearingSlot.styles'
import { ConfigContext } from '../../context/appConfig.jsx'

interface Props {
  content: Content
  transitionKey: string
  letter: string
  letterPrev?: string
  loc?: string
  screenType?: string
}

interface Content {
  description?: string
  jurisdiction?: string // jurisdiction
  caseNumber?: string // caseNumber
  caseType?: string // caseType
  room?: string // courtRoom
  time?: Date // hearingDateTime
  action?: string // hearingStatusText
  level?: string
  judgeName?: string
}

export const LobbyHearingSlot = ({
  content,
  transitionKey,
  letter,
  letterPrev = undefined,
  loc = '',
  screenType = '',
}: Props) => {
  const [inProp, setInProp] = useState(false)

  const nodeRef = useRef(null)
  const appConfig = useContext(ConfigContext)

  useEffect(() => {
    setInProp(false)
  }, [])

  useEffect(() => {
    if (inProp === false) {
      setTimeout(() => {
        setInProp(true)
      }, 300)
    }
  }, [inProp])

  const handleHearingTime = (hearingTime: Date) => {
    return hearingTime
      .toLocaleTimeString('en-US', {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
      })
      .toLocaleUpperCase()
  }

  const letterHeading = !letterPrev?.match(letter)

  const roomSegments = content.room?.split('.')
  const roomNumber =
    roomSegments && roomSegments.length > 0
      ? roomSegments[roomSegments.length - 1]
      : null

  const room = loc === 'wcjc' ? roomNumber : content.room

  return (
    <Transition in={inProp} timeout={300} nodeRef={nodeRef} key={transitionKey}>
      {(state) => {
        return (
          <StyledListingHearingSlot
            id={`hearingSlot${letter}`}
            state={state}
            letter={letterHeading}
            appConfig={appConfig}
            loc={loc}
          >
            {letterHeading && (
              <StyledListingOrder id="letterIdentifier">
                {letter}
              </StyledListingOrder>
            )}

            {content?.description && (
              <>
                <StyledHearingInfo
                  state={state}
                  letter={letterHeading}
                  appConfig={appConfig}
                >
                  <p id="hearingInfoMain">{content?.description}</p>
                  {loc.toLowerCase() === 'wcjc' && screenType === 'listing' ? (
                    <>
                      <span id="hearingInfoSub">{content?.caseType}</span>
                      <span id="hearingInfoSub">{content?.judgeName}</span>
                      <span id="hearingInfoSub">{content?.caseNumber}</span>
                    </>
                  ) : (
                    <span id="hearingInfoSub">
                      {content?.jurisdiction} - {content?.caseType} -{' '}
                      {content?.caseNumber}
                    </span>
                  )}
                </StyledHearingInfo>
                <StyledHearingTime
                  jur={
                    content?.jurisdiction === 'Magistrates Court' ||
                    (loc === 'wcjc' && screenType === 'listing')
                  }
                >
                  {content?.time && (
                    <p id="hearingTime">{handleHearingTime(content.time)}</p>
                  )}

                  <span id="hearingAction">{content?.action}</span>
                </StyledHearingTime>
                <StyledHearingRoom id="hearingRoom">
                  {content?.room === '-' && <p>-</p>}
                  {content?.room !== '-' && (
                    <>
                      <p id="hearingRoomNumber">{room}</p>
                      <span id="hearingRoomLevel">{content.level}</span>
                    </>
                  )}
                </StyledHearingRoom>
              </>
            )}
          </StyledListingHearingSlot>
        )
      }}
    </Transition>
  )
}
