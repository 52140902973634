import React from 'react'
import {
  StyledFooter,
  StyledFooterRow,
  StyledFooterRecord,
} from './Footer.styles'
import {
  BehaviourIcon,
  FoodIcon,
  ImagesIcon,
  MobileIcon,
  RecordingIcon,
  SmokingIcon,
} from './Icons'

interface Props {
  slim?: boolean
  progressBar?: boolean
}
export const Footer = ({ slim = false, progressBar = false }: Props) => {
  return (
    <StyledFooter
      slim={slim}
      progressBar={progressBar}
      data-testid="footer"
      id="footer"
    >
      <StyledFooterRow>
        <StyledFooterRecord id="footerMessage">
          <MobileIcon size="5vh" />
          <p id="messageMobile">
            Turn off mobile phones and electronic devices
          </p>
        </StyledFooterRecord>
        <StyledFooterRecord id="footerMessage">
          <RecordingIcon size="5vh" />
          <p id="messageVideo">
            No video, cameras
            <br />
            or tape recorders
          </p>
        </StyledFooterRecord>
        <StyledFooterRecord id="footerMessage">
          <ImagesIcon size="5vh" />
          <p id="messagePhoto">No photography</p>
        </StyledFooterRecord>
      </StyledFooterRow>
      <StyledFooterRow>
        <StyledFooterRecord id="footerMessage">
          <BehaviourIcon size="5vh" />
          <p id="messageShouting">No shouting or threatening behaviour</p>
        </StyledFooterRecord>
        <StyledFooterRecord id="footerMessage">
          <FoodIcon size="5vh" />
          <p id="messageFood">
            No eating or
            <br />
            chewing gum
          </p>
        </StyledFooterRecord>
        <StyledFooterRecord id="footerMessage">
          <SmokingIcon size="5vh" />
          <p id="messageSmoking">No smoking</p>
        </StyledFooterRecord>
      </StyledFooterRow>
    </StyledFooter>
  )
}
