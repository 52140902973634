import React, { useContext, useState } from 'react'
import { DateTimeHeader } from './dateTimeHeader/DateTimeHeader'
import {
  StyledLobbyScreen,
  StyledJurisdictionHeader,
} from './LobbyScreen.styles'
import { ContentHeader } from './ContentHeader'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import { StyledProgressWrapper } from '../ListingScreen/ListingScreen.styles'
import { LobbyScreenList } from './LobbyScreenList'
import { getLastIndex } from '../../util'
import { ConfigContext } from '../../context/appConfig.jsx'

interface Props {
  data?: HearingType[]
  screen?: string
  totalScreens?: string
}

export const LobbyScreen = ({
  data = [],
  screen = '1',
  totalScreens = '1',
}: Props) => {
  const [currentPage, setCurrentPage] = useState(1)
  const rowsPerPage = 9
  const pageCeiling = Math.ceil(
    data.length / (rowsPerPage * parseInt(totalScreens, 10))
  )

  const { lastScreen, recordsOnLastScreen } = getLastIndex(
    data,
    pageCeiling,
    totalScreens
  )

  const refreshEvent = (page: number) => {
    setCurrentPage(page)
  }

  const appConfig = useContext(ConfigContext)

  return (
    <StyledLobbyScreen appConfig={appConfig}>
      <DateTimeHeader />

      <StyledJurisdictionHeader id="jurisdictionHeader" appConfig={appConfig}>
        Schedule (A-Z)
      </StyledJurisdictionHeader>

      <ContentHeader
        content={{
          description: 'Name / Jurisdiction / Case type / ID',
          room: 'Room',
          time: 'Time',
        }}
      />

      <LobbyScreenList
        page={currentPage}
        data={data}
        screen={parseInt(screen, 10)}
        totalScreens={parseInt(totalScreens, 10)}
        lastScreen={lastScreen}
        recordsOnLastScreen={recordsOnLastScreen}
      />
      <StyledProgressWrapper id="progressBar" appConfig={appConfig}>
        {pageCeiling > 1 && (
          <ProgressBar
            height="3.4vh"
            onRefresh={refreshEvent}
            totalPages={pageCeiling}
            lastScreenPosition={lastScreen}
            thisScreenNumber={screen}
            totalPhysicalScreens={totalScreens}
            rightGradient={appConfig.minor_color}
            singleScreenDuration={6}
            backgroundColor={appConfig.progress_bar_color}
            progTextColor={appConfig.progress_bar_text_color}
          />
        )}
      </StyledProgressWrapper>
    </StyledLobbyScreen>
  )
}
