/* eslint-disable consistent-return */
import styled from 'styled-components'

interface StyledProps {
  empty?: boolean
  size?: 'large' | 'small'
  state: 'entering' | 'entered' | 'exiting' | 'exited' | 'unmounted'
  twoCol?: boolean
  jurisdiction?: ValidJurisdictionTypes
}

export const StyledCourtIdHearing = styled.li`
  box-sizing: border-box;
  display: grid;
  ${(props) => {
    if (props.size === 'large') {
      return `
      grid-template-columns: 3fr 0.5fr;
      gap: 6.1vw;
      `
    }
    if (props.size === 'small' && props.twoCol) {
      return `
      grid-template-columns: 3fr 1fr;
      gap: 2.1vw;
      `
    }
    if (props.size === 'small' && !props.twoCol) {
      return `
      grid-template-columns: 3fr 0.5fr;
      gap: 6.1vw;
      `
    }
  }}
  border-bottom: ${(props: StyledProps) =>
    props.empty && !props.twoCol
      ? '0.2vh solid #515557'
      : '0.2vh solid #414042'};
  height: ${(props: StyledProps) =>
    props.size === 'large' ? '9.5vh' : '5.4vh'};
  opacity: ${(props: StyledProps) =>
    props.state === 'entering' || props.state === 'entered' ? 1 : 0};
  padding: ${(props: StyledProps) =>
    props.size === 'large' ? '1.5vh 4vh' : '0.7vh 1vh 0.7vh 3vh'};
  transition: opacity 300ms ease-in-out;

  &:last-child {
    border-bottom: none;
  }

  h4 {
    color: white;
    display: -webkit-box;
    font-size: ${(props: StyledProps) =>
      props.size === 'large' ? '1.45vh' : '1.3vh'};
    font-weight: bold;
    letter-spacing: 0.025vh;
    line-height: 1.1;
    line-clamp: ${(props: StyledProps) =>
      props.jurisdiction === 'chcv' ? 1 : 2};
    -webkit-line-clamp: ${(props: StyledProps) =>
      props.jurisdiction === 'chcv' ? 1 : 2};
    margin: 0.1vh 0;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }

  p {
    color: #dddddd;
    display: -webkit-box;
    font-size: ${(props: StyledProps) =>
      props.size === 'large' ? '1.2vh' : '1vh'};
    /* ChCV line clamp */
    ${(props) => {
      if (props.size === 'large') {
        return `
      line-clamp: 2;
      -webkit-line-clamp: 2;
      `
      }
      if (props.size === 'small' && props.jurisdiction !== 'chcv') {
        return `
      line-clamp: 1;
      -webkit-line-clamp: 1;
      `
      }
      if (props.size === 'small' && props.jurisdiction === 'chcv') {
        return `
      line-clamp: 2;
      -webkit-line-clamp: 2;
      `
      }
    }}
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    max-width: 90%;
  }
`
export const StyledTimeWrapper = styled.section`
  color: white;
  white-space: nowrap;
  font-weight: bold;
  letter-spacing: 0.025vh;
  line-height: 1.1;
  font-size: ${(props: StyledProps) =>
    props.size === 'large' ? '1.45vh' : '1.3vh'};
`
