import React from 'react'
import { CourtIdHeader } from './CourtIdHeader'
import {
  StyledDirections,
  StyledCourtIdScreen,
  StyledJurisdictionHeader,
  ClosedCourtroomSpace,
  ClosedCourtroomJudges,
} from './CourtIdScreen.styles'
import { Footer } from './Footer/Footer'

interface CourtroomClosedProps {
  location?: string
  config?: any
}

const CancelIcon = () => {
  return (
    <span
      style={{
        height: 'calc(100% - 2vh)',
        display: 'block',
        marginLeft: '3.5vh',
        padding: '1vh 0',
      }}
    >
      <svg
        id="cancelicon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 144.59 144.59"
        height="100%"
      >
        <g id="Text">
          <g>
            <line
              stroke="white"
              strokeWidth="2.74"
              strokeMiterlimit="10"
              fill="none"
              x1="20.72"
              y1="20.72"
              x2="123.87"
              y2="123.87"
            />
            <line
              stroke="white"
              strokeWidth="2.74"
              strokeMiterlimit="10"
              fill="none"
              x1="123.87"
              y1="20.72"
              x2="20.72"
              y2="123.87"
            />
          </g>
        </g>
      </svg>
    </span>
  )
}

export const CourtroomClosed: React.FC<CourtroomClosedProps> = ({
  location = undefined,
  config = undefined,
}) => {
  const transformedLocation =
    location && location.charAt(0).toUpperCase() + location.slice(1)

  const showCancelIcon = () => {
    if (config && config?.displayCross?.toLowerCase() === 'true') {
      return <CancelIcon />
    }
    return ''
  }

  return (
    <StyledCourtIdScreen id="courtRoomClosed">
      <CourtIdHeader />
      <StyledJurisdictionHeader id="jurisdictionHeader">
        {transformedLocation} Law Courts
      </StyledJurisdictionHeader>
      <ClosedCourtroomJudges>{showCancelIcon()}</ClosedCourtroomJudges>
      <StyledDirections bg="orange" id="courtRoomClosedMessage">
        {config?.courtroomClosedMsg}
      </StyledDirections>
      <ClosedCourtroomSpace />
      <Footer />
    </StyledCourtIdScreen>
  )
}
