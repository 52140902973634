import React, { useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import {
  StyledJudgesHeader,
  StyledJudge,
  StyledJudgesSection,
} from './CourtIdScreen.styles'

const judgeLabels = {
  mcv: 'Judicial Officer',
  cc: 'Judicial Officer',
  sc: 'Judicial Officer',
  vcat: 'Member',
  chcv: 'Judicial Officer',
  iv: '',
}

interface JudgeProps {
  judgeName: string
  isLong?: boolean
  jurisdiction?: ValidJurisdictionTypes
  iVHearings?: () => boolean
}

export const Judge = ({
  judgeName,
  isLong = undefined,
  jurisdiction = undefined,
  iVHearings = () => false,
}: JudgeProps): JSX.Element => {
  const [inProp, setInProp] = useState(false)
  const nodeRef = useRef(null)

  useEffect(() => {
    setInProp(false)
  }, [])

  useEffect(() => {
    if (inProp === false) {
      setTimeout(() => {
        setInProp(true)
      }, 300)
    }
  }, [inProp])

  return (
    <Transition in={inProp} timeout={300} nodeRef={nodeRef}>
      {(state) => {
        return (
          // Change the styling for judge component for IV hearings
          <StyledJudge
            id="displayedJudge"
            state={state}
            isLong={isLong}
            jurisdiction={jurisdiction}
          >
            {judgeName}
          </StyledJudge>
        )
      }}
    </Transition>
  )
}

interface Props {
  data: HearingType[]
  jurisdiction: ValidJurisdictionTypes
  slim?: boolean
  fixture?: string
  iVHearings?: () => boolean
  config?: any
}

export const Judges: React.FC<Props> = ({
  data,
  jurisdiction,
  slim = false,
  fixture = undefined,
  iVHearings = () => false,
  config = undefined,
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  const judgeSectionSize: 'large' | 'small' =
    jurisdiction === 'mcv' ||
    jurisdiction === 'vcat' ||
    jurisdiction === 'chcv' ||
    jurisdiction === 'iv'
      ? 'small'
      : 'large'

  const allJudgeNames: string[] = []
  data.forEach((hearing) => {
    if (hearing.judgeName != null) {
      const exploded = hearing.judgeName.split(',')
      exploded.forEach((judge) => {
        allJudgeNames.push(judge.trim())
      })
    }
  })

  function checkJudgeName(judgeName: string) {
    return judgeName !== '-' || judgeName.length === 0 ? judgeName : null
  }

  const judgeNames = Array.from(new Set(allJudgeNames))
  const judgeNamesResults = judgeNames.filter(checkJudgeName)

  // checking if there is a long judge for length 35 or greater for SC and CC screens
  const isLongJudge = (judges: string[]): boolean => {
    const longestJudge = Math.max(...judges.map((el) => el.length))

    if (longestJudge >= 35) {
      return true
    }
    return false
  }

  const judgesPerPage =
    (judgeSectionSize === 'small' && jurisdiction === 'mcv') ||
    (judgeSectionSize === 'small' && jurisdiction === 'chcv') ||
    (judgeSectionSize === 'small' && jurisdiction === 'vcat') ||
    (judgeSectionSize === 'large' &&
      jurisdiction === 'sc' &&
      isLongJudge(judgeNamesResults)) ||
    (judgeSectionSize === 'large' &&
      jurisdiction === 'cc' &&
      isLongJudge(judgeNamesResults))
      ? 1
      : 3

  const pageCeiling = Math.ceil(judgeNamesResults.length / judgesPerPage)

  const getRecordIndexByPage = (): number => {
    return judgesPerPage * (currentPage - 1)
  }

  const isIV = iVHearings()

  useEffect(() => {
    let page = 1
    const timer = setInterval(() => {
      if (page === pageCeiling) {
        page = 1
      } else {
        page += 1
      }

      setCurrentPage(page)
    }, 6000)

    return () => {
      clearInterval(timer)
    }
  }, [judgeNames.length, pageCeiling])

  useEffect(() => {
    setCurrentPage(1)
  }, [jurisdiction])

  return (
    <StyledJudgesSection id="judgesSection" size={judgeSectionSize} slim={slim}>
      <StyledJudgesHeader id="judgesHeader">
        {fixture?.match(/conciliation conference/i)
          ? 'Convenor'
          : judgeLabels[jurisdiction]}
      </StyledJudgesHeader>
      {Array.from({ length: judgesPerPage }, (item, index: number) => {
        const updatedIndex = getRecordIndexByPage() + index
        if (judgeNamesResults[updatedIndex]) {
          return (
            <Judge
              key={judgeNamesResults[updatedIndex]}
              judgeName={judgeNamesResults[updatedIndex]}
              isLong={judgeNamesResults[updatedIndex].length >= 33}
              jurisdiction={jurisdiction}
            />
          )
        }

        return ''
      })}
      {judgeNamesResults.length === 0 && (
        <Judge
          judgeName={
            isIV === true && jurisdiction === 'iv'
              ? config?.interventionHearings?.title
              : '-'
          }
          iVHearings={iVHearings}
          jurisdiction={jurisdiction}
        />
      )}
    </StyledJudgesSection>
  )
}
