import React, { useContext, useEffect } from 'react'
import { ListingHearingSlot } from './ListingHearingSlot'
import { getRandomKey } from '../../util'
import { StyledList, StyledEOD } from './ListingScreenList.styles'
import { LobbyHearingSlot } from '../LobbyScreen/LobbyHearingSlot'
import { ConfigContext } from '../../context/appConfig.jsx'

interface Props {
  page: number
  screen: number
  totalScreens: number
  data: HearingType[]
  loc?: string
  screenType?: string
  setJurisdiction: (jurisdiction: string) => void
  setNextJurisdiction: (jurisdiction: string) => void
}

interface FullJurisdiction {
  mcv: string
  cc: string
  sc: string
  vcat: string
  chcv: string
  none: string
}

const courts: FullJurisdiction = {
  mcv: 'Magistrates Court',
  cc: 'County Court',
  sc: 'Supreme Court',
  vcat: 'VCAT',
  chcv: 'Childrens Court',
  none: '',
}

export const ListingScreenList: React.FC<Props> = ({
  page,
  screen,
  totalScreens,
  data,
  loc = '',
  screenType = '',
  setJurisdiction,
  setNextJurisdiction,
}: Props) => {
  const getRecordIndexByPage = (): number => {
    return 9 * ((page - 1) * totalScreens) + 9 * (screen - 1)
  }

  // Setting current and next jurisdiction on every page change
  useEffect(() => {
    const index = getRecordIndexByPage()

    if (!(data.length === index + 9)) {
      const pagedIndex = getRecordIndexByPage() + 9
      setNextJurisdiction(data[pagedIndex]?.jurisdiction)
    } else {
      setNextJurisdiction(data[0]?.jurisdiction)
    }
    setJurisdiction(data[index]?.jurisdiction)
  }, [page])

  const appConfig = useContext(ConfigContext)

  return (
    <StyledList appConfig={appConfig}>
      {Array.from({ length: 9 }, (item, index: number) => {
        const pagedIndex = getRecordIndexByPage() + index
        if (data[pagedIndex]) {
          if (data[pagedIndex]?.endOfScheduleFlag === true) {
            return (
              <StyledEOD key="eol" data-testid={index + 1}>
                <h3>** This is the last page of the schedule **</h3>
              </StyledEOD>
            )
          }
          if (['sc', 'mcv', 'cc'].includes(data[pagedIndex]?.jurisdiction)) {
            const number = pagedIndex - 1
            const checkChar = /^([0-9])$/
            const checkLetter = checkChar.test(
              data[pagedIndex]?.matterDescription?.charAt(0)
            )
              ? '#'
              : data[pagedIndex].matterDescription[0]

            const checkPrev = checkChar.test(
              data[number]?.matterDescription?.[0]
            )
              ? '#'
              : data[number]?.matterDescription?.[0]
            const previousRecord = checkPrev

            return (
              <LobbyHearingSlot
                key={getRandomKey(data[pagedIndex].hearingId)}
                transitionKey={data[pagedIndex].hearingId}
                content={{
                  description: data[pagedIndex].matterDescription,
                  jurisdiction:
                    courts[
                      data[pagedIndex].jurisdiction as keyof FullJurisdiction
                    ],
                  caseNumber: data[pagedIndex].caseNumber,
                  room: data[pagedIndex].courtRoom,
                  time: new Date(`${data[pagedIndex].hearingDateTime}`),
                  action: data[pagedIndex].hearingStatusText,
                  level: data[pagedIndex].level,
                  caseType: data[pagedIndex].caseType,
                  judgeName: data[pagedIndex].judgeName,
                }}
                letter={checkLetter}
                letterPrev={previousRecord}
                data-testid={index + 1}
                loc={loc}
                screenType={screenType}
              />
            )
          }
          return (
            <ListingHearingSlot
              key={getRandomKey(data[pagedIndex].hearingId)}
              transitionKey={data[pagedIndex].hearingId}
              content={{
                description: data[pagedIndex].matterDescription,
                descriptionLower: `${data[pagedIndex].caseType} - ${data[pagedIndex].caseNumber}`,
                room: data[pagedIndex].courtRoom,
                time: new Date(`${data[pagedIndex].hearingDateTime}`),
                action: data[pagedIndex].hearingStatusText,
                jurisdiction: data[pagedIndex].jurisdiction,
                hearingType: data[pagedIndex].hearingType,
                caseNumber: data[pagedIndex].caseNumber,
                level: data[pagedIndex].level,
              }}
              data-testid={index + 1}
            />
          )
        }
        // if (index === recordsOnLastScreen && screen === lastScreen) {
        //   return (
        //     <StyledEOD key="eol">
        //       <h3>** This is the last page of the schedule **</h3>
        //     </StyledEOD>
        //   )
        // }
        return (
          <ListingHearingSlot
            key={getRandomKey(index.toString())}
            transitionKey={index.toString()}
            content={{}}
          />
        )
      })}
    </StyledList>
  )
}
