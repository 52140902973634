import React, { useContext, useMemo, useState } from 'react'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import { ContentHeader } from './ContentHeader'
import { DateTimeHeader } from './dateTimeHeader/DateTimeHeader'
import { ListingScreenList } from './ListingScreenList'
import { JurisdictionHeader } from './jurisdictionHeader/JurisdictionHeader'
import {
  StyledListingScreen,
  StyledProgressWrapper,
} from './ListingScreen.styles'
import { getLastIndex, getJurisdictions, groupData } from '../../util'
import { ConfigContext } from '../../context/appConfig.jsx'

interface Props {
  data?: HearingType[]
  screen?: string
  totalScreens?: string
  loc?: string
  screenType?: string
}

const rowsPerPage = 9

export const ListingScreen: React.FC<Props> = ({
  data = [],
  screen = '1',
  totalScreens = '1',
  loc = '',
  screenType = '',
}: Props): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1)
  const [currentJurisdiction, setJurisdiction] = useState('')
  const [nextJurisdiction, setNextJurisditcion] = useState('')
  const jurisdictions = useMemo(() => getJurisdictions(data), [data])
  const appConfig = useContext(ConfigContext)

  const cachedValue = useMemo(() => {
    return groupData(data, jurisdictions)
  }, [data, jurisdictions])

  const pageCeiling = Math.ceil(
    cachedValue.sortedData.length / (rowsPerPage * parseInt(totalScreens, 10))
  )

  const { lastScreen } = getLastIndex(
    cachedValue.sortedData,
    pageCeiling,
    totalScreens
  )

  // CHANGE
  const contentDescription =
    currentJurisdiction && currentJurisdiction === 'chcv'
      ? 'Case / details'
      : 'Name / Case type / ID'

  const refreshEvent = (page: number) => {
    setCurrentPage(page)
  }

  const addLeftSpacing = ['sc', 'cc', 'mcv'].includes(
    currentJurisdiction?.toLowerCase()
  )
  return (
    <StyledListingScreen appConfig={appConfig}>
      <DateTimeHeader leftSpacing={addLeftSpacing} loc={loc} />
      <JurisdictionHeader
        // jurisdiction={
        //   screen === '1' ? (jurisdictions as ValidJurisdictionTypes[]) : 'none'
        // }
        loc={loc}
        jurisdiction={jurisdictions as ValidJurisdictionTypes[]}
        jur={currentJurisdiction}
        nextJurisdiction={nextJurisdiction}
        screen={parseInt(screen, 10)}
        totalScreens={parseInt(totalScreens, 10)}
        pageCeiling={pageCeiling}
        pivots={cachedValue.pivotsWithPageValues}
        page={currentPage}
      />

      <ContentHeader
        currentJurisdiction={currentJurisdiction}
        content={{
          description: `${contentDescription}`,
          room: 'Room',
          time: 'Time',
        }}
        addLeftSpacing={addLeftSpacing}
      />

      <ListingScreenList
        page={currentPage}
        data={cachedValue?.sortedData}
        // pageCeiling={pageCeiling}
        screen={parseInt(screen, 10)}
        totalScreens={parseInt(totalScreens, 10)}
        setJurisdiction={setJurisdiction}
        setNextJurisdiction={setNextJurisditcion}
        loc={loc}
        screenType={screenType}
      />
      <StyledProgressWrapper id="progressBar" appConfig={appConfig}>
        {pageCeiling > 1 && (
          <ProgressBar
            height="3.4vh"
            onRefresh={refreshEvent}
            totalPages={pageCeiling}
            lastScreenPosition={lastScreen}
            thisScreenNumber={screen}
            totalPhysicalScreens={totalScreens}
            rightGradient={appConfig.minor_color}
            singleScreenDuration={
              process.env.NODE_ENV !== 'test'
                ? Math.round(
                    Number(appConfig.progress_bar_speed) /
                      parseInt(totalScreens, 10)
                  )
                : Math.round(24 / parseInt(totalScreens, 10))
            }
            backgroundColor={appConfig.progress_bar_color}
            progTextColor={appConfig.progress_bar_text_color}
          />
        )}
      </StyledProgressWrapper>
    </StyledListingScreen>
  )
}
// 1. Fix end of data msg
// Remove jurisdiction as reqd parameter from url
