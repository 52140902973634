import styled from 'styled-components'

interface HearingsListProps {
  columns: 1 | 2
}

interface JudgesSectionProps {
  size: 'large' | 'small'
  slim?: boolean
}

interface ProgressProps {
  slim?: boolean
}

interface JudgeProps {
  state: string
  isLong?: boolean
  jurisdiction?: ValidJurisdictionTypes
}

export const StyledCourtIdScreen = styled.div`
  background-color: #616668;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  flex-direction: column;
  height: 100vh;
`

interface StyledJurisdictionHeaderProps {
  faded?: boolean
  slim?: boolean
}
export const StyledJurisdictionHeader = styled.h1<StyledJurisdictionHeaderProps>`
  align-items: center;
  background-color: #515557;
  border-bottom: 0.2vh solid #414042;
  color: ${(props) => (props.faded ? '#6c6f72' : '#ffffff')};
  display: flex;
  font-size: 4vh;
  height: 8vh;
  margin: 0;
  padding-left: ${(props) => (props.slim ? '3vh' : '4vh')};
  padding-right: ${(props) => (props.slim ? '3vh' : '4vh')};
  justify-content: space-between;
`
export const StyledNextJurisdictionHeader = styled.div`
  font-size: 1.9vh;
  font-weight: 100;
  display: flex;
  flex-direction: column;
`
export const StyledJudgesHeader = styled.h3`
  font-size: 1.8vh;
  font-weight: lighter;
  margin: 0.2vh 0 0;
`
interface DirectionsProps {
  bg?: 'green' | 'orange'
  slim?: boolean
}
export const StyledDirections = styled.section<DirectionsProps>`
  align-items: center;
  background-color: ${(props) =>
    props.bg && props.bg === 'orange' ? '#b36240' : '#6db264'};
  border-bottom: 0.2vh solid #414042;
  border-top: 0.2vh solid #414042;
  color: #ffffff;
  display: flex;
  font-size: 2.6vh;
  font-weight: lighter;
  height: 5vh;
  padding: ${(props) => (props.slim ? '0 3vh' : '0 4vh')};
`
export const StyledHearingsWrapper = styled.div`
  background-color: #515557;
  display: flex;

  ol:nth-child(2) {
    border-left: 0.2vh solid #414042;
  }
`
export const StyledHearingsList = styled.ol<HearingsListProps>`
  list-style: none;
  margin: 0;
  padding: unset;
  width: ${(props) => (props.columns === 1 ? '100%' : '50%')};

  &:nth-child(2) {
    width: calc(50% - 0.2vh);
  }
`
export const StyledJudgesSection = styled.section<JudgesSectionProps>`
  background-color: #6db264;
  color: #000000;
  overflow: hidden;
  padding: ${(props) => (props.slim ? '0.6vh 3vh' : '0.6vh 4vh')};
  height: ${(props) => (props.size === 'large' ? '15vh' : '9vh')};
`
export const StyledClerkSection = styled.section<JudgesSectionProps>`
  border-top: 0.2vh solid #414042;
  background-color: #6db264;
  color: #000000;
  overflow: hidden;
  padding: ${(props) => (props.slim ? '0.6vh 3vh' : '0.6vh 4vh')};
  height: ${(props) => (props.size === 'large' ? '15vh' : '9vh')};
`
export const StyledJudge = styled.p<JudgeProps>`
  font-size: 3vh;
  font-weight: bold;
  margin: 0;
  color: ${(props: JudgeProps) => (props.jurisdiction === 'iv' ? 'white' : '')};
  padding-top: ${(props: JudgeProps) =>
    props.jurisdiction === 'iv' ? '2vh' : 0};
  opacity: ${(props: JudgeProps) =>
    props.state === 'entering' || props.state === 'entered' ? 1 : 0};
  transition: opacity 300ms ease-in-out;

  display: -webkit-box;
  /* judge on SC and CC */
  /* line-clamp: 1; */
  line-clamp: ${(props) =>
    (props.isLong && props.jurisdiction === 'cc') ||
    (props.isLong && props.jurisdiction === 'sc')
      ? '3'
      : '1'};
  /* -webkit-line-clamp: 1; */
  -webkit-line-clamp: ${(props) =>
    (props.isLong && props.jurisdiction === 'cc') ||
    (props.isLong && props.jurisdiction === 'sc')
      ? '3'
      : '1'};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`

export const StyledProgressWrapper = styled.div<ProgressProps>`
  background: #616668;
  border-top: 0.2vh solid #414042;
  font-size: 1.1vh;
  height: 1.4vh;
  text-indent: ${(props) => (props.slim ? '3vh' : '4vh')};
`
export const ClosedCourtroomSpace = styled.div`
  display: block;
  height: 55vh;
`
export const ClosedCourtroomJudges = styled.div`
  background-color: #b36240;
  display: block;
  height: 9vh;
`

export const StyledHearingType = styled.div`
  color: #ffffff;
  font-size: 6vh;
  padding: 1vh 4vh;
  width: calc(100% - 8vh);
  height: 52vh;
`
