import React, { useCallback, useEffect, useRef, useState } from 'react'
import { StyledProgressIndicator, StyledGradient } from './ProgressBar.style'
import { clamp, getCurrentPage } from '../../util'

interface Props {
  totalPhysicalScreens?: string
  thisScreenNumber?: string
  totalPages?: number
  jurisdictionCurrentPage?: number
  jurisdictionTotalPages?: number
  lastScreenPosition?: number
  singleScreenDuration?: number
  onRefresh?: (page: number) => void
  height?: string
  rightGradient?: string
  backgroundColor?: string
  progTextColor?: string
}

const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef<() => void>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return undefined
  }, [delay])
}

export const ProgressBar = ({
  totalPhysicalScreens = '1',
  thisScreenNumber = '1',
  totalPages = 1,
  jurisdictionCurrentPage = undefined,
  jurisdictionTotalPages = totalPages,
  lastScreenPosition = 1,
  singleScreenDuration = Math.round(24 / parseInt(totalPhysicalScreens, 10)),
  onRefresh = () => null,
  height = '1.4vh',
  rightGradient = '#f4f2f2',
  backgroundColor = '#f4f2f2',
  progTextColor = 'white',
}: Props): JSX.Element => {
  const [percentage, setPercentage] = useState(0)
  const [showIndicator, setShowIndicator] = useState(true)
  const [showGradient, setShowGradient] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)

  const numPhysicalScreens = parseInt(totalPhysicalScreens, 10)
  const thisScreen = parseInt(thisScreenNumber, 10)

  const numVirtualScreens =
    numPhysicalScreens * (totalPages - 1) + lastScreenPosition

  const fadeInDelay = 1

  const pageDuration = fadeInDelay + numPhysicalScreens * singleScreenDuration

  const totalFadeInDelay = fadeInDelay * totalPages
  const totalDuration =
    singleScreenDuration * numVirtualScreens + totalFadeInDelay

  useEffect(() => {
    const indicatorTimeout = setTimeout(() => {
      setShowIndicator(true)
    }, 500)
    return () => clearTimeout(indicatorTimeout)
  }, [showIndicator])

  const reset = (page: number) => {
    setCurrentPage(page)
    onRefresh(page)
    setShowIndicator(false)
  }

  const delayHideGradient = () => {
    setTimeout(() => {
      setShowGradient(false)
    }, 1000)
  }

  const setInitialPage = useCallback(() => {
    const page = getCurrentPage(totalDuration, pageDuration)
    setCurrentPage(page)
    onRefresh(page)
  }, [totalDuration, pageDuration, onRefresh])

  // Set parent screen to correct page on init
  useEffect(() => {
    setInitialPage()
  }, [setInitialPage])

  useInterval(() => {
    const seconds = Math.floor(Date.now() / 1000)

    const totalProgress = seconds % totalDuration
    const pageProgress = totalProgress % pageDuration

    const page = Math.floor(totalProgress / pageDuration) + 1

    if (pageProgress === 0 || totalProgress === 0) {
      reset(page)
    }

    const thisScreenOffset = (thisScreen - 1) * singleScreenDuration
    const thisScreenProgress = clamp(
      pageProgress - thisScreenOffset,
      0,
      singleScreenDuration
    )

    if (thisScreenProgress >= singleScreenDuration) {
      delayHideGradient()
    } else {
      setShowGradient(true)
    }

    setPercentage(thisScreenProgress)
  }, 1000)

  const fillPercent = (percentage / singleScreenDuration) * 100

  return (
    <div style={{ height, display: 'flex' }}>
      {showIndicator && (
        <StyledProgressIndicator
          style={{
            width: `${fillPercent}%`,
          }}
          data-testid="progress"
          id="progress"
          progBg={backgroundColor}
          textColor={progTextColor}
        >
          {thisScreenNumber === '1' && (
            <span id="progressPageNumber" data-testid="progressPageNumber">
              Page {jurisdictionCurrentPage ?? currentPage} of{' '}
              {jurisdictionTotalPages}
            </span>
          )}
        </StyledProgressIndicator>
      )}
      {showGradient && (
        <StyledGradient
          progBg={backgroundColor}
          rightGradient={rightGradient}
        />
      )}
    </div>
  )
}
