import React, { useContext } from 'react'
import { LobbyHearingSlot } from './LobbyHearingSlot'
import { getRandomKey } from '../../util'
import { StyledList, StyledEOD } from './LobbyScreenList.styles'
import { ConfigContext } from '../../context/appConfig.jsx'

interface Props {
  page: number
  screen: number
  totalScreens: number
  data: HearingType[]
  lastScreen: number
  recordsOnLastScreen: number
}

interface FullJurisdiction {
  mcv: string
  cc: string
  sc: string
  vcat: string
  chcv: string
  none: string
}

const courts: FullJurisdiction = {
  mcv: 'Magistrates Court',
  cc: 'County Court',
  sc: 'Supreme Court',
  vcat: 'VCAT',
  chcv: 'Childrens Court',
  none: '',
}

export const LobbyScreenList: React.FC<Props> = ({
  page,
  screen,
  totalScreens,
  data,
  lastScreen,
  recordsOnLastScreen,
}: Props) => {
  const getRecordIndexByPage = (): number => {
    return 9 * ((page - 1) * totalScreens) + 9 * (screen - 1)
  }

  const appConfig = useContext(ConfigContext)

  return (
    <StyledList appConfig={appConfig}>
      {Array.from({ length: 9 }, (item, index: number) => {
        const pagedIndex = getRecordIndexByPage() + index
        if (data[pagedIndex]) {
          const number = pagedIndex - 1

          const checkChar = /^([0-9])$/
          const checkLetter = checkChar.test(
            data[pagedIndex].matterDescription[0]
          )
            ? '#'
            : data[pagedIndex].matterDescription[0]

          const checkPrev = checkChar.test(data[number]?.matterDescription[0])
            ? '#'
            : data[number]?.matterDescription[0]
          const previousRecord = checkPrev

          return (
            <LobbyHearingSlot
              key={getRandomKey(data[pagedIndex].hearingId)}
              transitionKey={data[pagedIndex].hearingId}
              content={{
                description: data[pagedIndex].matterDescription,
                jurisdiction:
                  courts[
                    data[pagedIndex].jurisdiction as keyof FullJurisdiction
                  ],
                caseNumber: data[pagedIndex].caseNumber,
                caseType: data[pagedIndex].caseType,
                room: data[pagedIndex].courtRoom,
                time: new Date(`${data[pagedIndex].hearingDateTime}`),
                action: data[pagedIndex].hearingStatusText,
                level: data[pagedIndex].level,
              }}
              letter={checkLetter}
              letterPrev={previousRecord}
            />
          )
        }

        if (index === recordsOnLastScreen && screen === lastScreen) {
          return (
            <StyledEOD key="eol">
              <h3>** This is the last page of the schedule **</h3>
            </StyledEOD>
          )
        }

        return (
          <LobbyHearingSlot
            key={getRandomKey(index.toString())}
            transitionKey={index.toString()}
            content={{}}
            letter=""
          />
        )
      })}
    </StyledList>
  )
}
