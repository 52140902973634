import React, { useContext } from 'react'
import {
  StyledListingHearingSlot,
  StyledHearingInfo,
  StyledHearingDate,
  StyledHearingTime,
} from './ContentHeader.styles'
import { ConfigContext } from '../../context/appConfig.jsx'

interface Props {
  content: Content
}

interface Content {
  description?: string
  room?: string
  time?: string
}

export const ContentHeader = ({ content }: Props) => {
  const appConfig = useContext(ConfigContext)

  return (
    <StyledListingHearingSlot
      id="hearingContentIdentifier"
      data-testid="lobby-content-header"
      appConfig={appConfig}
    >
      <StyledHearingInfo>
        <h2>{content?.description}</h2>
      </StyledHearingInfo>
      <StyledHearingTime>{content && <p>Time</p>}</StyledHearingTime>
      <StyledHearingDate>
        <p>{content?.room}</p>
      </StyledHearingDate>
    </StyledListingHearingSlot>
  )
}
