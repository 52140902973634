import React, { useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import {
  StyledCourtIdHearing,
  StyledTimeWrapper,
} from './CourtIdHearingSlot.styles'

interface Props {
  content: Content
  size: 'large' | 'small'
  twoCol?: boolean
  jurisdiction?: ValidJurisdictionTypes
}

interface Content {
  matterDescription?: string
  caseNumber?: string
  caseType?: string
  hearingType?: string
  hearingDateTime?: Date
  timeString?: string
}

export const CourtIdHearingSlot = ({
  content,
  size,
  twoCol = false,
  jurisdiction = undefined,
}: Props): JSX.Element => {
  const [inProp, setInProp] = useState(false)
  const nodeRef = useRef(null)

  const handleTimeString = (timeString: string) => {
    const hours: string | number = timeString.substring(11, 13)
    let hour: string | number = parseInt(hours, 10)

    const minutes: string | number = timeString.substring(14, 16)

    let minute: string | number = parseInt(minutes, 10)

    const ampm = hour >= 12 ? 'PM' : 'AM'

    hour %= 12
    hour = hour || 12 // the hour '0' should be '12'
    minute = minute < 10 ? `${minutes}` : minutes
    const time = `${hour}:${minute} ${ampm}`
    return time
  }

  useEffect(() => {
    setInProp(false)
  }, [])

  useEffect(() => {
    if (inProp === false) {
      setTimeout(() => {
        setInProp(true)
      }, 300)
    }
  }, [inProp])

  return (
    <Transition in={inProp} timeout={300} nodeRef={nodeRef}>
      {(state) => {
        return (
          <StyledCourtIdHearing
            id="hearingSlot"
            state={state}
            size={size}
            empty={!content?.matterDescription}
            twoCol={twoCol}
            jurisdiction={jurisdiction}
          >
            {content?.matterDescription && (
              <div>
                {/* chcv headings */}
                {content.hearingType?.match(/heading/i) &&
                  jurisdiction?.match(/chcv/i) && (
                    <h4 id="hearingInfoMain">{content?.matterDescription}</h4>
                  )}
                {/* other jurisdictions */}
                {!jurisdiction?.match(/chcv/i) && (
                  <h4 id="hearingInfoMain">{content?.matterDescription}</h4>
                )}
                {/* Chcv when fixtures not heading */}
                {!content.hearingType?.match(/heading/i) &&
                  jurisdiction?.match(/chcv/i) && (
                    <h4 id="hearingInfoMain">
                      {content?.matterDescription} {content?.caseNumber}
                    </h4>
                  )}
                {/* ChCV when not a heading hearingType */}
                {!content.hearingType?.match(/heading/i) &&
                  jurisdiction?.match(/chcv/i) && (
                    <p data-testid="hearing-sub-content" id="hearingInfoSub">
                      {content?.hearingType}
                    </p>
                  )}
                {/* other jurisdictions */}
                {!jurisdiction?.match(/chcv/i) && (
                  <p data-testid="hearing-sub-content" id="hearingInfoSub">
                    {content?.caseType} - {content?.hearingType} -{' '}
                    {content?.caseNumber}
                  </p>
                )}
              </div>
            )}
            {content?.hearingDateTime && content?.timeString && (
              <StyledTimeWrapper
                data-testid="hearing-time"
                id="hearingTime"
                state={state}
                size={size}
                empty={!content?.matterDescription}
                twoCol={twoCol}
              >
                {handleTimeString(content?.timeString)}
              </StyledTimeWrapper>
            )}
          </StyledCourtIdHearing>
        )
      }}
    </Transition>
  )
}
