import React, { useState, useEffect } from 'react'
import { StyledDateTimeHeader } from './CourtIdHeader.styles'

interface CourtIdHeaderProps {
  slim?: boolean
}

export const CourtIdHeader = ({ slim = undefined }: CourtIdHeaderProps) => {
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000)
    return function cleanup() {
      clearInterval(timer)
    }
  })

  return (
    <StyledDateTimeHeader
      id="dateTimeHeader"
      data-testid="date-time-header"
      slim={slim}
    >
      <div data-testid="custom-date" id="dateDisplay">
        {date
          .toLocaleDateString('en-GB', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })
          .replace(/,/g, '')}
      </div>
      <div data-testid="custom-time" id="timeDisplay">
        {' '}
        {date
          .toLocaleTimeString('en-US', {
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          })
          .toLocaleUpperCase()}
      </div>
    </StyledDateTimeHeader>
  )
}
