import React, { useState, useEffect, useContext } from 'react'
import { StyledDateTimeHeader } from './DateTimeHeader.styles'
import logo from '../../../assets/images/logo_high_res.png'
import { ConfigContext } from '../../../context/appConfig.jsx'

interface Props {
  leftSpacing?: boolean
  loc?: string
}

export const DateTimeHeader: React.FC<Props> = ({
  leftSpacing = false,
  loc = '',
}: Props) => {
  const [date, setDate] = useState(new Date())
  const appConfig = useContext(ConfigContext)

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000)
    return function cleanup() {
      clearInterval(timer)
    }
  })

  let padding = ''
  if (loc !== 'wcjc') {
    if (leftSpacing) {
      padding = '10.6vw'
    } else {
      padding = '3vw'
    }
  } else {
    padding = '0vw'
  }

  return (
    <StyledDateTimeHeader
      data-testid="date-time-header"
      leftSpacing={padding}
      appConfig={appConfig}
    >
      {loc === 'wcjc' && <img data-testid="img-logo" src={logo} alt="Logo" />}
      <div data-testid="custom-date" id="dateDisplay">
        {date
          .toLocaleDateString('en-GB', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })
          .replace(/,/g, '')}
      </div>
      <div id="timeDisplay" data-testid="custom-time">
        {' '}
        {date
          .toLocaleTimeString('en-US', {
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          })
          .toLocaleUpperCase()}
      </div>
    </StyledDateTimeHeader>
  )
}
