/* eslint-disable consistent-return */
import styled from 'styled-components'

interface StyledProps {
  state: 'entering' | 'entered' | 'exiting' | 'exited' | 'unmounted'
  appConfig?: any
}

export const StyledListingHearingSlot = styled.li`
  box-sizing: border-box;
  color: ${(props) => props.appConfig.text_color};
  background-color: ${(props) => props.appConfig.minor_color};
  border-bottom: 0.2vh solid ${(props) => props.appConfig.border_color};
  height: 9.3vh;
  padding-top: 0.75vh;
  padding-left: 5.6vw;
  display: grid;
  grid-template-columns: 2fr 0.7fr 1fr;
  gap: 1.1vw;

  opacity: ${(props: StyledProps) =>
    props.state === 'entering' || props.state === 'entered' ? 1 : 0};
  transition: opacity 300ms ease-in-out;

  p {
    font-size: 1.5vh;
    font-weight: bold;
    margin: 0;
  }
  span {
    font-size: 1.2vh;
    font-weight: normal;
    margin: 0;
    color: ${(props) => props.appConfig.subtext_color};
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
`

export const StyledHearingInfo = styled.div`
  padding-right: 3.1vw;
  box-sizing: border-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  margin: 0.1vh 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  h4 {
    font-weight: bold;
    display: -webkit-box;
    font-size: 1.5vh;
    font-weight: bold;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    margin: 0.1vh 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    line-height: 1.3;
  }
  span {
    font-size: 1.2vh;
    color: ${(props: StyledProps) => props.appConfig.subtext_color};
    line-clamp: 1;
    -webkit-line-clamp: 1;
    margin: 0.1vh 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    line-height: 1.3;
  }
`

export const StyledHearingInfoChcv = styled.div`
  padding-right: 3.1vw;
  box-sizing: border-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  margin: 0.1vh 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  h4 {
    font-weight: bold;
    display: -webkit-box;
    font-size: 1.5vh;
    font-weight: bold;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    margin: 0.1vh 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    line-height: 1.3;
  }
  span {
    font-size: 1.2vh;
    color: ${(props: StyledProps) => props.appConfig.subtext_color};
    line-clamp: 2;
    -webkit-line-clamp: 2;
    margin: 0.1vh 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    line-height: 1.3;
  }
`

export const StyledHearingDate = styled.div`
  box-sizing: border-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  margin: 0.1vh 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  p {
    font-size: 1.5vh;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    margin: 0.1vh 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    word-wrap: nowrap;
  }

  span {
    line-clamp: 1;
    -webkit-line-clamp: 1;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    font-size: 1.2vh;
  }
`
export const StyledHearingTime = styled.div`
  box-sizing: border-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  margin: 0.1vh 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  p {
    line-clamp: 1;
    -webkit-line-clamp: 1;
    margin: 0.1vh 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
  span {
    width: 17vw;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    margin: 0.1vh 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
`
