import React, { useState, useEffect, useContext } from 'react'
import { StyledDateTimeHeader } from './DateTimeHeader.styles'
import { ConfigContext } from '../../../context/appConfig.jsx'

export const DateTimeHeader = () => {
  const [date, setDate] = useState(new Date())
  const appConfig = useContext(ConfigContext)

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000)
    return function cleanup() {
      clearInterval(timer)
    }
  })

  return (
    <StyledDateTimeHeader
      id="dateTimeHeader"
      data-testid="date-time-header"
      appConfig={appConfig}
    >
      <div id="dateDisplay" data-testid="custom-date">
        {date
          .toLocaleDateString('en-GB', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })
          .replace(/,/g, '')}
      </div>
      <div id="timeDisplay" data-testid="custom-time">
        {' '}
        {date
          .toLocaleTimeString('en-US', {
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          })
          .toLocaleUpperCase()}
      </div>
    </StyledDateTimeHeader>
  )
}
