import React from 'react'
import { CourtIdHearingSlot } from './CourtIdHearingSlot'
import {
  StyledHearingsList,
  StyledHearingsWrapper,
} from './CourtIdScreen.styles'
import { getRandomKey } from '../../util'

interface Props {
  data: HearingType[]
  rowsPerPage: 5 | 10 | 20 | 8
  page: number
  jurisdiction?: ValidJurisdictionTypes
}

export const CourtIdHearingList: React.FC<Props> = ({
  data,
  rowsPerPage,
  page,
  jurisdiction = undefined,
}: Props) => {
  const getRecordIndexByPage = (): number => {
    return rowsPerPage * (page - 1)
  }
  const columns = rowsPerPage === 20 ? 2 : 1
  const rowSize = rowsPerPage === 5 ? 'large' : 'small'
  const rowIterator = rowsPerPage === 20 ? 10 : rowsPerPage

  return (
    <StyledHearingsWrapper>
      <StyledHearingsList id="singleColumnView" columns={columns}>
        {Array.from({ length: rowIterator }, (item, index: number) => {
          const pagedIndex = getRecordIndexByPage() + index
          if (data[pagedIndex]) {
            return (
              <CourtIdHearingSlot
                key={getRandomKey(data[pagedIndex].hearingId)}
                content={{
                  matterDescription: data[pagedIndex].matterDescription,
                  caseNumber: data[pagedIndex].caseNumber,
                  caseType: data[pagedIndex].caseType,
                  hearingType: data[pagedIndex].hearingType,
                  hearingDateTime: new Date(
                    `${data[pagedIndex].hearingDateTime}`
                  ),
                  timeString: data[pagedIndex].timeString,
                }}
                size={rowSize}
                twoCol={!!(columns === 2)}
                jurisdiction={jurisdiction}
              />
            )
          }
          return (
            <CourtIdHearingSlot
              key={`col1-${pagedIndex}`}
              content={{}}
              size={rowSize}
              twoCol={!!(columns === 2)}
            />
          )
        })}
      </StyledHearingsList>
      {columns === 2 && (
        <StyledHearingsList id="doubleColumnView" columns={columns}>
          {Array.from({ length: rowIterator }, (item, index: number) => {
            const updatedIndex = getRecordIndexByPage() + index + rowIterator
            if (data[updatedIndex]) {
              return (
                <CourtIdHearingSlot
                  key={getRandomKey(data[updatedIndex].hearingId)}
                  content={{
                    matterDescription: data[updatedIndex].matterDescription,
                    caseNumber: data[updatedIndex].caseNumber,
                    caseType: data[updatedIndex].caseType,
                    hearingType: data[updatedIndex].hearingType,
                    hearingDateTime: new Date(
                      `${data[updatedIndex].hearingDateTime}`
                    ),
                    timeString: data[updatedIndex].timeString,
                  }}
                  size={rowSize}
                  twoCol
                />
              )
            }
            return (
              <CourtIdHearingSlot
                key={`col2-${updatedIndex}`}
                content={{}}
                size={rowSize}
                twoCol
              />
            )
          })}
        </StyledHearingsList>
      )}
    </StyledHearingsWrapper>
  )
}
