import styled from 'styled-components'

interface Props {
  accomodateProgressBar?: boolean
}
export const StyledFVSpace = styled.div<Props>`
  background-color: #515557;
  display: block;
  height: ${(props) =>
    props.accomodateProgressBar === true ? '47.5vh' : '50vh'};
  padding: 3vh;
  color: white;
  font-size: 2.6vh;
`
export const StyledTitleSection = styled.div`
  background-color: #6db264;
  color: #ffffff;
  font-size: 2.6vh;
  font-weight: lighter;
  height: 9vh;
  padding: 0 3vh;
  display: flex;
  align-items: center;
`
