import styled from 'styled-components'

interface Props {
  addLeftSpacing?: boolean
  appConfig?: any
}

export const StyledListingHearingSlot = styled.li<Props>`
  box-sizing: border-box;
  color: ${(props) => props.appConfig.text_color};
  background-color: ${(props) => props.appConfig.minor_color};
  border-bottom: 0.2vh solid ${(props) => props.appConfig.border_color};
  height: 3.4vh;
  align-items: center;
  padding-left: ${(props) => (props.addLeftSpacing ? '12.8vw' : '5.6vw')};
  display: grid;
  grid-template-columns: ${(props) =>
    props.addLeftSpacing ? '1.75fr 0.75fr 0.4fr' : '2fr 0.7fr 1fr'};
  gap: 1.1vw;

  p {
    font-size: 1.3vh;
    font-weight: normal;
    margin: 0;
    padding: 0;
    color: ${(props) => props.appConfig.text_color};
  }
`

export const StyledHearingInfo = styled.div`
  box-sizing: border-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  margin: 0.1vh 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;

  p {
    display: -webkit-box;
    font-size: 1.3vh;
    font-weight: normal;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    margin: 0.1vh 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
`

export const StyledHearingDate = styled.div<Props>`
  padding-left: ${(props) => (props.addLeftSpacing ? '1vw' : '0')};
  line-clamp: 1;
  -webkit-line-clamp: 1;
  margin: 0.1vh 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`
export const StyledHearingTime = styled.div`
  line-clamp: 1;
  -webkit-line-clamp: 1;
  margin: 0.1vh 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`
