import styled from 'styled-components'

interface StyledProgressIndicatorProps {
  progBg?: string
  textColor?: string
}
export const StyledProgressIndicator = styled.div`
  align-items: center;
  background: ${(props: StyledProgressIndicatorProps) => props.progBg};
  color: ${(props: StyledProgressIndicatorProps) => props.textColor};
  display: flex;
  height: 100%;
  transition: width 1s linear;
  white-space: nowrap;

  span {
    z-index: 2;
  }
`

interface StyledGradientProps {
  progBg: string
  rightGradient: string
}
export const StyledGradient = styled.span`
  background-image: linear-gradient(
    to right,
    ${(props: StyledGradientProps) => props.progBg},
    ${(props: StyledGradientProps) => props.rightGradient}
  );
  height: 100%;
  width: 2vw;
  margin-left: -2vw;
`
