import styled from 'styled-components'

interface FooterProps {
  slim?: boolean
  progressBar?: boolean
}
export const StyledFooter = styled.footer<FooterProps>`
  background-color: #616668;
  border-top: 0.2vh solid #414042;
  color: white;
  height: 14vh;
  overflow: hidden;
  padding-left: ${(props) => (props.slim ? '3vh' : '4vh')};
  padding-top: ${(props) => (props.progressBar ? '0.8vh' : '1.4vh')};
`
export const StyledFooterRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1vh 0;
  width: 100%;
`
export const StyledFooterRecord = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-basis: 0;
  flex-direction: row;
  flex-grow: 1;
  font-family: inherit;
  padding-right: 1vh;

  span {
    flex-basis: 0;
    flex-grow: 0.5;
  }

  p {
    flex-basis: 0;
    flex-grow: 1.5;
    font-size: 1.05vh;
    margin: 0;
    padding-left: 1vh;
    word-break: break-word;
  }
`
