import { RenderOptions, render } from '@testing-library/react'
import React, { ReactElement } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { MemoryRouter } from 'react-router-dom'

// sort an array of HearingType objects by hearingDateTime and then by matterDescription
export const sortHearingsTimeThenMatter = (
  hearings: HearingType[]
): HearingType[] => {
  return hearings.sort((a: HearingType, b: HearingType): number => {
    if (a.hearingDateTime === b.hearingDateTime) {
      if (b.matterDescription < a.matterDescription) {
        return 1
      }
    }
    if (a.hearingDateTime > b.hearingDateTime) {
      return 1
    }
    return -1
  })
}

/*
  alphabetize hearings into first letter groups, then order by hearing time and then alphabetical
  again once it's in it's letter group
*/
export const sortHearingsAlphabeticallyAndTime = (
  hearings: HearingType[]
): HearingType[] => {
  const alpha = hearings.sort((a, b) =>
    a.matterDescription.localeCompare(b.matterDescription)
  )

  let letter: HearingType[] = []
  let sortedHearings: HearingType[] = []

  alpha.forEach((hearing, index) => {
    letter.push(hearing)

    if (
      index === alpha.length - 1 ||
      hearing.matterDescription.slice(0, 1) !==
        alpha[index + 1].matterDescription.slice(0, 1)
    ) {
      letter = sortHearingsTimeThenMatter(letter)
      sortedHearings = [...sortedHearings, ...letter]
      letter = []
    }
  })

  return sortedHearings
}

/*
  alphabetize hearings into first letter (matterDescription)
*/
export const sortHearingsStrictAlphabetically = (
  hearings: HearingType[]
): HearingType[] => {
  const alpha = hearings.sort((a, b) =>
    a.matterDescription.localeCompare(b.matterDescription)
  )

  return alpha
}

export const isValidJurisdiction = (
  jurisdiction: string | undefined
): jurisdiction is ValidJurisdictionTypes => {
  return (
    jurisdiction !== undefined &&
    ['sc', 'cc', 'mcv', 'vcat', 'chcv'].includes(jurisdiction)
  )
}

/* 
  validate URL parameters and return an array of any errors found.
  valid params return empty array
*/

const validURLParams = [
  'location',
  'screenType',
  'courtRoom',
  'totalScreens',
  'thisScreenNum',
]

export const toUrlParams = (params: URLSearchParams): UrlParamsType => {
  return {
    location: params.get('location')?.toLowerCase(),
    screen: params.get('screenType')?.toLowerCase(),
    courtRoom: params.get('courtRoom')?.toLowerCase(),
    totalScreens: params.get('totalScreens')?.toLowerCase(),
    thisScreenNum: params.get('thisScreenNum')?.toLowerCase(),
  }
}

export const validateUrlParams = (searchParams: URLSearchParams): string[] => {
  const errors: string[] = []

  // Show errors for any unsupported params
  const paramNames = Array.from(searchParams.keys())
  const invalidParamNames = paramNames.filter(
    (p) => !validURLParams.includes(p)
  )

  invalidParamNames.forEach((p) =>
    errors.push(`'${p}' is not a valid query parameter`)
  )

  // Validate remaining param valuies
  const params = toUrlParams(searchParams)

  const screenTypeValid =
    params.screen && ['lobby', 'listing', 'courtid'].includes(params.screen)
  if (!screenTypeValid) {
    errors.push('Invalid Screen Type Received')
  }

  // POC locations
  const csvwLocations = process.env.REACT_APP_CSVW_LOCATIONS?.split(' ')
  const screenLocation =
    params.location && csvwLocations?.includes(params.location)
  if (!screenLocation) {
    errors.push('Invalid Location Received')
  }

  if (params.screen === 'courtid' && !params.courtRoom) {
    errors.push('Court ID Screen requires courtId')
  }
  if (
    params.screen === 'lobby' &&
    (!params.totalScreens || !params.thisScreenNum)
  ) {
    errors.push('Lobby screens require totalScreens and thisScreenNum')
  }
  return errors
}

// verify hearing data record has what we need to show the information
export const isValidHearingRecord = (hearing: any): hearing is HearingType => {
  if (!hearing || typeof hearing !== 'object') {
    return false
  }
  if (
    !hearing.jurisdiction ||
    typeof hearing.jurisdiction !== 'string' ||
    !['sc', 'cc', 'mcv', 'vcat', 'chcv'].includes(hearing.jurisdiction)
  ) {
    return false
  }

  if (!hearing.hearingId) {
    return false
  }
  if (!hearing.hearingDateTime) {
    return false
  }
  if (!hearing.matterDescription) {
    return false
  }
  if (!hearing.courtRoom) {
    return false
  }

  return true
}

// verify hearing data record has what we need to show the information for lobby screen
export const isValidHearingRecordLobby = (
  hearing: ApiResponseObject
): hearing is HearingType => {
  if (!isValidHearingRecord(hearing)) return false

  if (!hearing.courtRoom && !['mcv'].includes(hearing.jurisdiction))
    return false

  return true
}

// calculate what page to load on init based on total pages and system time
export const getStartPage = (totalPages: number): number => {
  const bound = totalPages * 250

  const d = new Date()
  const msSinceMidnight = Math.floor(
    (d.getTime() - d.setHours(0, 0, 0, 0)) / 100
  )

  const mod = msSinceMidnight % bound
  let startPage = totalPages
  for (let pg = 1; pg <= totalPages; pg += 1) {
    if (mod < pg * 250) {
      startPage = pg
      break
    }
  }

  return startPage
}

export const getCurrentPage = (totalDuration: number, pageDuration: number) => {
  const seconds = Math.floor(Date.now() / 1000)
  const totalProgress = seconds % totalDuration
  const currentPage = Math.floor(totalProgress / pageDuration) + 1

  return currentPage
}

// random unique string for keys in array iteration
export const getRandomKey = (seed = ''): string => {
  // this is here to guarantee unique array keys for the hearings.  we had an issue where
  // hearing ids were doubling and messing it up
  return `${seed}-${Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .slice(0, 5)}`
}

// Calculate last screen and index
export const getLastIndex = (
  rows: Row[],
  totalPages: number,
  totalScreens: string
) => {
  const residualRecords =
    rows.length - 9 * (totalPages - 1) * parseInt(totalScreens, 10)
  const lastScreen = Math.ceil(residualRecords / 9)
  const recordsOnLastScreen = residualRecords - 9 * (lastScreen - 1)

  return { lastScreen, recordsOnLastScreen }
}

interface TestHearingParameters {
  hearingId?: string | string[]
  matterDescription?: string | string[]
  jurisdiction?: string | string[]
  caseNumber?: string | string[]
  caseType?: string | string[]
  hearingStatus?: string | string[]
  hearingStatusText?: string | string[]
  hearingDateTime?: Date | Date[]
  courtRoom?: string | string[]
  level?: string | string[]
  judgeName?: string | string[]
  hearingType?: string | string[]
  jurToRoomMappings?: any
}

const AllTheProviders = ({
  children,
  initialEntries = undefined,
}: {
  children: React.ReactNode
  initialEntries?: string[]
}) => {
  return (
    <MemoryRouter initialEntries={initialEntries}>
      <QueryClientProvider client={new QueryClient()}>
        {children}
      </QueryClientProvider>
    </MemoryRouter>
  )
}

export const apiRender = (
  ui: ReactElement,
  initialEntries?: string[],
  options?: Omit<RenderOptions, 'queries'>
) =>
  render(ui, {
    wrapper: ({ children }: { children: React.ReactNode }) => (
      <AllTheProviders initialEntries={initialEntries}>
        {children}
      </AllTheProviders>
    ),
    ...options,
  })

export const createTestHearing = ({
  hearingId = `a32524c9-de61-4141-95a1-eb10d11e70`,
  matterDescription = 'Matter Description',
  jurisdiction = 'mcv',
  caseNumber = 'H394/2019',
  caseType = 'Directions Hearing',
  hearingStatus = 'Hearing Scheduled',
  hearingStatusText = 'Please checkin ',
  hearingDateTime = new Date('2022-06-21T09:00:00'),
  courtRoom = '4A',
  judgeName = 'Vice President Judge Marks',
  hearingType = 'VALID TYPE',
}: TestHearingParameters = {}) => {
  return {
    hearingId,
    matterDescription,
    jurisdiction,
    caseNumber,
    caseType,
    hearingStatus,
    hearingStatusText,
    hearingDateTime,
    courtRoom,
    judgeName,
    hearingType,
  } as HearingType
}

const singleOrSample = (value: string | string[] | undefined) => {
  if (value === undefined) {
    return undefined
  }
  if (typeof value === 'string') {
    return value
  }
  return value[Math.floor(Math.random() * value.length)]
}

export const createTestHearingsOfLength = (
  length: number,
  {
    hearingId = undefined,
    matterDescription = undefined,
    jurisdiction = 'mcv',
    caseNumber = 'H394/2019',
    caseType = 'Directions Hearing',
    hearingStatus = 'Hearing Scheduled',
    hearingStatusText = 'Please checkin ',
    hearingDateTime = new Date('2022-06-21T09:00:00'),
    courtRoom = '4A',
    level = 'Level 4',
    judgeName = 'Vice President Judge Marks',
    hearingType = 'VALID TYPE',
    jurToRoomMappings = {},
  }: TestHearingParameters = {}
) => {
  // const data: HearingType[] = []

  // Object.keys(jurToRoomMappings).forEach((jur) => {
  //   const currentObj = jurToRoomMappings[jur]
  //   for (let c = 0; c < currentObj.count; c += 1) {
  //     data.push({
  //       hearingId:
  //         singleOrSample(hearingId) ?? `a32524c9-de61-4141-95a1-eb10d11e7${c}`,
  //       matterDescription:
  //         singleOrSample(matterDescription) ?? `Record ${c + 1} of ${length}`,
  //       jurisdiction: jur as ValidJurisdictionTypes,
  //       caseNumber: singleOrSample(caseNumber),
  //       caseType: singleOrSample(caseType),
  //       hearingStatus: singleOrSample(hearingStatus),
  //       hearingStatusText: singleOrSample(hearingStatusText),
  //       hearingDateTime: hearingDateTime as Date,
  //       courtRoom: currentObj.courtRooms[c % currentObj.courtRooms.length],
  //       // level: singleOrSample(level),
  //       level: singleOrSample(currentObj.level),
  //       judgeName: singleOrSample(judgeName),
  //       hearingType: singleOrSample(hearingType),
  //     })
  //   }
  // })

  // return data

  return Array(length)
    .fill(0)
    .map((_, i) => {
      return {
        hearingId:
          singleOrSample(hearingId) ?? `a32524c9-de61-4141-95a1-eb10d11e7${i}`,
        matterDescription:
          singleOrSample(matterDescription) ?? `Record ${i + 1} of ${length}`,
        jurisdiction: singleOrSample(jurisdiction),
        // jurisdiction: jurisdiction[Math.floor(i / length)],
        caseNumber: singleOrSample(caseNumber),
        caseType: singleOrSample(caseType),
        hearingStatus: singleOrSample(hearingStatus),
        hearingStatusText: singleOrSample(hearingStatusText),
        hearingDateTime,
        courtRoom: singleOrSample(courtRoom),
        // courtRoom: courtRoom[Math.floor(i / length)],
        level: singleOrSample(level),
        judgeName: singleOrSample(judgeName),
        hearingType: singleOrSample(hearingType),
      }
    }) as HearingType[]
}

export const clamp = (value: number, min: number, max: number) => {
  return Math.max(Math.min(value, max), min)
}
export const getJurisdictions = (
  data: HearingType[]
): ValidJurisdictionTypes[] => {
  const jurisdictions =
    data.length > 0
      ? Array.from(
          new Set(
            data.map((record) => {
              return record.jurisdiction
            })
          )
        )
      : []
  return jurisdictions
}

export const createMapWithKeysAndDefaultValue = <K, V>(
  keys: K[],
  defaultValue: () => V
): Map<K, V> => {
  const map = new Map<K, V>()

  keys.forEach((key) => {
    map.set(key, defaultValue())
  })

  return map
}

const groupByJurisdiction = (
  hearings: HearingType[],
  jurisdictions: ValidJurisdictionTypes[]
) => {
  const hearingsByJurisdiction = createMapWithKeysAndDefaultValue(
    jurisdictions,
    () => [] as HearingType[]
  )

  // For each record add to corresponding jurisdiction bucket
  hearings.forEach((hearing) => {
    const { jurisdiction } = hearing
    hearingsByJurisdiction.get(jurisdiction)?.push(hearing)
  })

  return hearingsByJurisdiction
}

const sortHearings = (
  hearingsByJurisdiction: Map<ValidJurisdictionTypes, HearingType[]>
) => {
  // Sort the data : different jurisdictions have different sorting criteria
  // ChCv - already sorted in API response
  // VCAT - sort by time
  // Rest - sort by matter description

  hearingsByJurisdiction.forEach((hearings, jurisdiction) => {
    if (['sc', 'cc', 'mcv'].includes(jurisdiction)) {
      hearingsByJurisdiction.set(
        jurisdiction,
        sortHearingsStrictAlphabetically(hearings)
      )
    } else if (jurisdiction === 'vcat') {
      hearingsByJurisdiction.set(
        jurisdiction,
        sortHearingsTimeThenMatter(hearings)
      )
    }
  })

  return hearingsByJurisdiction
}

const padHearings = (
  hearingsByJurisdiction: Map<ValidJurisdictionTypes, HearingType[]>
) => {
  const endOfScheduleRow: EndOfScheduleRow = { endOfScheduleFlag: true }
  const rowsByJurisdiction = new Map<ValidJurisdictionTypes, Row[]>()

  // Pad hearings with empty rows and end of data messages
  hearingsByJurisdiction.forEach((hearings, jurisdiction) => {
    const emptyRowsOnFinalScreen = 9 - (hearings.length % 9)

    // Add nulls for each empty row (minus one for end of data msg)
    const fillNulls = Array.from<null>({
      length: emptyRowsOnFinalScreen - 1,
    }).fill(null)

    // If there are no empty rows on final screen
    if (hearings.length % 9 === 0) {
      // Don't add end of data msg or empty rows
      rowsByJurisdiction.set(jurisdiction, hearings)
    }
    if (hearings.length % 9 !== 0) {
      // Otherwise add end of data msg and nulls to fill all remaining rows
      rowsByJurisdiction.set(jurisdiction, [
        ...hearings,
        endOfScheduleRow,
        ...fillNulls,
      ])
    }
  })

  return rowsByJurisdiction
}

type EndOfScheduleRow = {
  endOfScheduleFlag: boolean
}

export type Row = HearingType | EndOfScheduleRow | null

export const groupData = (
  hearings: HearingType[],
  jurisdictions: ValidJurisdictionTypes[]
) => {
  let hearingsByJurisdiction = groupByJurisdiction(hearings, jurisdictions)
  hearingsByJurisdiction = sortHearings(hearingsByJurisdiction)

  // Pad hearings with null/end of data rows to fill screens
  const rowsByJurisdiction = padHearings(hearingsByJurisdiction)

  // Flatten rows into 1d array
  let sortedData: any[] = []
  rowsByJurisdiction.forEach((rows) => {
    sortedData = [...sortedData, ...rows]
  })

  // The number of screens each jurisdiction takes up
  const screenCounts = new Map<ValidJurisdictionTypes, number>()

  rowsByJurisdiction.forEach((rows, jurisdiction) => {
    screenCounts.set(jurisdiction, rows.length / 9)
  })

  // Total pages taken up by the previous jurisdictions
  let previousScreens = 0

  // The position of each jurisdiction's last screen
  const lastScreenPositions = new Map<ValidJurisdictionTypes, number>()

  screenCounts.forEach((screenCount, jurisdiction) => {
    const pageEnd = screenCount + previousScreens
    lastScreenPositions.set(jurisdiction, pageEnd)

    previousScreens += screenCount
  })

  // Convert to object for listing screen
  const pivotsWithPageValues: { [jur: string]: number | undefined } = {}

  lastScreenPositions.forEach((screenPos, jurisdiction) => {
    pivotsWithPageValues[jurisdiction] = screenPos
  })

  return { sortedData, pivotsWithPageValues }
}
