import styled from 'styled-components'

interface StyledDateTimeHeaderProps {
  slim?: boolean
}

export const StyledDateTimeHeader = styled.div<StyledDateTimeHeaderProps>`
  font-family: inherit;
  font-size: 2.5vh;
  color: #ffffff;
  background-color: #616668;
  border-bottom: 0.2vh solid #414042;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5.3956vh;
  padding-left: ${(props) => (props.slim ? '0.8vh' : '2vh')};
  div {
    align-items: center;
    display: flex;
    height: 5.38vh;
    padding: 0 2vh;
  }
  div:last-child {
    border-left: 0.2vh solid #414042;
  }
`
