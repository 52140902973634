import React from 'react'

interface Props {
  error: string | string[]
}

export const ErrorScreen = ({ error }: Props): JSX.Element => {
  const isArr = Array.isArray(error)
  return (
    <>
      {isArr &&
        error.map((err) => (
          <p style={{ color: 'red' }} key={err}>
            {err}
          </p>
        ))}
      {!isArr && <p style={{ color: 'red' }}>{error}</p>}
    </>
  )
}
