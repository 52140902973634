import React, { useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import {
  StyledJudgesHeader,
  StyledJudge,
  StyledClerkSection,
} from './CourtIdScreen.styles'

const judgeLabels = {
  mcv: `Magistrate`,
  cc: 'Judges',
  sc: 'Judges',
  vcat: 'Member',
  chcv: 'Clerk',
  iv: 'Magistrate',
}

interface JudgeProps {
  judgeName: string
}

export const Judge = ({ judgeName }: JudgeProps): JSX.Element => {
  const [inProp, setInProp] = useState(false)
  const nodeRef = useRef(null)

  useEffect(() => {
    setInProp(false)
  }, [])

  useEffect(() => {
    if (inProp === false) {
      setTimeout(() => {
        setInProp(true)
      }, 300)
    }
  }, [inProp])
  return (
    <Transition in={inProp} timeout={300} nodeRef={nodeRef}>
      {(state) => {
        return (
          <StyledJudge
            data-testid="clerk-name"
            id="displayedClerk"
            state={state}
          >
            {judgeName}
          </StyledJudge>
        )
      }}
    </Transition>
  )
}

interface Props {
  data: HearingType[]
  jurisdiction: ValidJurisdictionTypes
  slim?: boolean
}

export const Clerk: React.FC<Props> = ({
  data,
  jurisdiction,
  slim = false,
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  const judgeSectionSize: 'large' | 'small' =
    jurisdiction === 'mcv' || jurisdiction === 'vcat' || jurisdiction === 'chcv'
      ? 'small'
      : 'large'
  const judgesPerPage = judgeSectionSize === 'small' ? 1 : 3
  const allJudgeNames: string[] = []
  data.forEach((hearing) => {
    if (hearing.clerkName != null) {
      const exploded = hearing.clerkName.split(',')
      exploded.forEach((judge) => {
        allJudgeNames.push(judge.trim())
      })
    }
  })

  function checkClerkName(judgeName: string) {
    return judgeName !== '-' || judgeName.length === 0 ? judgeName : null
  }

  const judgeNames = Array.from(new Set(allJudgeNames))
  const clerkNamesResults = judgeNames.filter(checkClerkName)

  const pageCeiling = Math.ceil(clerkNamesResults.length / judgesPerPage)

  const getRecordIndexByPage = (): number => {
    return judgesPerPage * (currentPage - 1)
  }

  useEffect(() => {
    let page = 1
    const timer = setInterval(() => {
      if (page === pageCeiling) {
        page = 1
      } else {
        page += 1
      }

      setCurrentPage(page)
    }, 6000)

    return () => {
      clearInterval(timer)
    }
  }, [judgeNames.length, pageCeiling])

  return (
    <StyledClerkSection id="judgesSection" size={judgeSectionSize} slim={slim}>
      <StyledJudgesHeader id="judgesHeader">
        {judgeLabels[jurisdiction]}
      </StyledJudgesHeader>
      {Array.from({ length: judgesPerPage }, (item, index: number) => {
        const updatedIndex = getRecordIndexByPage() + index
        if (clerkNamesResults[updatedIndex]) {
          return (
            <Judge
              key={clerkNamesResults[updatedIndex]}
              judgeName={clerkNamesResults[updatedIndex]}
            />
          )
        }
        return ''
      })}
      {clerkNamesResults.length === 0 && <Judge judgeName="-" />}
    </StyledClerkSection>
  )
}
