import styled from 'styled-components'

interface Props {
  leftSpacing?: string
  appConfig?: any
}
export const StyledDateTimeHeader = styled.div<Props>`
  font-family: inherit;
  color: ${(props) => props.appConfig.text_color};
  background-color: ${(props) => props.appConfig.minor_color};
  border-bottom: 0.2vh solid ${(props) => props.appConfig.border_color};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5.3956vh;
  font-size: 2.4vh;
  // padding-left: 3vw;
  // padding-left: ${(props) => (props.leftSpacing ? '10.6vw' : '3vw')};
  padding-left: ${(props) => props.leftSpacing};
  div {
    align-items: center;
    display: flex;
    height: 5.38vh;
    padding: 0 2vw;
  }
  div:last-child {
    border-left: 0.2vh solid ${(props) => props.appConfig.border_color};
  }
  img {
    height: 100%;
  }
`
