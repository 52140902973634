import React, { useContext, useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import {
  StyledListingHearingSlot,
  StyledHearingInfo,
  StyledHearingInfoChcv,
  StyledHearingDate,
  StyledHearingTime,
} from './ListingHearingSlot.styles'
import { ConfigContext } from '../../context/appConfig.jsx'

interface Props {
  content: Content
  transitionKey: string
}

interface Content {
  description?: string
  descriptionLower?: string
  room?: string // courtRoom
  time?: Date // hearingDateTime
  action?: string // hearingStatusText
  jurisdiction?: ValidJurisdictionTypes
  hearingType?: string
  caseNumber?: string
  level?: string
}

export const ListingHearingSlot = ({ content, transitionKey }: Props) => {
  const [inProp, setInProp] = useState(false)
  const nodeRef = useRef(null)
  const appConfig = useContext(ConfigContext)

  useEffect(() => {
    setInProp(false)
  }, [])

  useEffect(() => {
    if (inProp === false) {
      setTimeout(() => {
        setInProp(true)
      }, 300)
    }
  }, [inProp])

  const handleHearingTime = (hearingTime: Date) => {
    return hearingTime
      .toLocaleTimeString('en-US', {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
      })
      .toLocaleUpperCase()
  }

  return (
    <Transition in={inProp} timeout={300} nodeRef={nodeRef} key={transitionKey}>
      {(state) => {
        return (
          <StyledListingHearingSlot
            id="hearingSlot"
            state={state}
            appConfig={appConfig}
          >
            {content?.description && (
              <>
                {/* VCAT and other Jurisdictions */}
                {content?.jurisdiction !== 'chcv' && (
                  <StyledHearingInfo state={state} appConfig={appConfig}>
                    <h4 id="hearingInfoMain">{content?.description}</h4>
                    <span id="hearingInfoSub">{content?.descriptionLower}</span>
                  </StyledHearingInfo>
                )}
                {/* Chcv */}
                {content?.jurisdiction === 'chcv' && (
                  <StyledHearingInfoChcv state={state} appConfig={appConfig}>
                    {content?.jurisdiction === 'chcv' &&
                    !content?.hearingType?.toLowerCase().match(/heading/i) ? (
                      <h4 id="hearingInfoMain">
                        {content?.description} {content?.caseNumber}
                      </h4>
                    ) : (
                      <h4 id="hearingInfoMain">{content?.description}</h4>
                    )}

                    {content?.jurisdiction !== 'chcv' && (
                      <span id="hearingInfoSub">
                        {content?.descriptionLower}
                      </span>
                    )}
                    {content?.jurisdiction === 'chcv' &&
                      !content?.hearingType
                        ?.toLowerCase()
                        .match(/heading/i) && (
                        <span id="hearingInfoSub">{content?.hearingType}</span>
                      )}
                  </StyledHearingInfoChcv>
                )}

                <StyledHearingTime>
                  {content?.time && (
                    <>
                      <p id="hearingTime">{handleHearingTime(content.time)}</p>
                      <span id="hearingAction">{content?.action}</span>
                    </>
                  )}
                </StyledHearingTime>

                {content?.jurisdiction !== 'chcv' && (
                  <StyledHearingDate id="hearingRoom">
                    {content?.room === '-' && <p>-</p>}
                    {content?.room !== '-' && (
                      <>
                        <p id="hearingRoomNumber">{content?.room}</p>
                        <span id="hearingRoomLevel">{content.level}</span>
                      </>
                    )}
                  </StyledHearingDate>
                )}
                {content?.jurisdiction === 'chcv' && (
                  <StyledHearingDate id="hearingRoom">
                    {content?.room === '-' && <p>-</p>}
                    {content?.room !== '-' && (
                      <>
                        <p id="hearingRoomNumber">{content?.room}</p>
                        <span id="hearingRoomLevel">{content.level}</span>
                      </>
                    )}
                    <br />
                  </StyledHearingDate>
                )}
              </>
            )}
          </StyledListingHearingSlot>
        )
      }}
    </Transition>
  )
}
